var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    {
      staticClass:
        "flex flex-col-reverse sm:flex-row md:flex-col-reverse lg:flex-row"
    },
    [
      _c("h1", { staticClass: "sr-only" }, [_vm._v("Project listings")]),
      _vm._v(" "),
      _c(
        "section",
        {
          staticClass:
            "w-full sm:w-1/3 md:w-full lg:w-1/3 xl:w-1/4 h-full sm:h-screen md:h-full lg:h-(screen-16) overflow-y-auto border-t sm:border-t-0 md:border-t lg:border-t-0 sm:border-r md:border-r-0 lg:border-r border-black"
        },
        [
          _c("section", { staticClass: "m-2" }, [
            !_vm.$route.params.id
              ? _c(
                  "div",
                  [
                    _c("header", [
                      _c(
                        "form",
                        {
                          staticClass: "text-base",
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                            }
                          }
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sr-only",
                              attrs: { for: "searchInput" }
                            },
                            [_vm._v("Search")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "mt-1 relative rounded" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "absolute inset-y-0 left-0 px-2 flex items-center pointer-events-none"
                              },
                              [_c("Search")],
                              1
                            ),
                            _vm._v(" "),
                            _c("input", {
                              staticClass:
                                "appearance-none placeholder-gray-600 w-full px-3 pl-8 py-2 bg-gray-100 border border-gray-500 rounded shadow focus:outline-none focus:shadow-outline",
                              attrs: {
                                id: "searchInput",
                                name: "searchInput",
                                type: "search",
                                role: "searchbox",
                                placeholder: "Search projects...",
                                required: "required"
                              },
                              domProps: { value: _vm.searchText },
                              on: {
                                input: function($event) {
                                  return _vm.handleSearchChange(
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "section",
                      {
                        staticClass:
                          "my-2 border border-gray-500 rounded shadow bg-gray-100 text-gray-900",
                        attrs: { id: "filters" }
                      },
                      [
                        _c(
                          "header",
                          { class: { "border-b": _vm.showFilters } },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "p-2 w-full flex items-center justify-between",
                                on: {
                                  click: function($event) {
                                    _vm.showFilters = !_vm.showFilters
                                  }
                                }
                              },
                              [
                                _c("h2", { staticClass: "flex items-center" }, [
                                  _c("i", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.feather.icons["settings"].toSvg({
                                          class: "w-5 h-5"
                                        })
                                      )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "px-2" }, [
                                    _vm._v("Settings")
                                  ])
                                ]),
                                _vm._v(" "),
                                !_vm.showFilters
                                  ? _c("i", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.feather.icons[
                                            "chevron-down"
                                          ].toSvg({ class: "w-5 h-5" })
                                        )
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showFilters
                                  ? _c("i", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.feather.icons[
                                            "chevron-up"
                                          ].toSvg({ class: "w-5 h-5" })
                                        )
                                      }
                                    })
                                  : _vm._e()
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "main",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showFilters,
                                expression: "showFilters"
                              }
                            ],
                            staticClass: "p-2",
                            attrs: { "aria-expanded": "" + _vm.showFilters }
                          },
                          [
                            _c(
                              "form",
                              {
                                staticClass: "grid grid-cols-1 gap-1",
                                on: {
                                  submit: function($event) {
                                    $event.preventDefault()
                                  }
                                }
                              },
                              [
                                _c(
                                  "h3",
                                  { staticClass: "my-2 text-gray-800 text-lg" },
                                  [_vm._v("Project settings")]
                                ),
                                _vm._v(" "),
                                _vm._l(_vm.dataset, function(model) {
                                  return _c("div", { key: model.key }, [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "flex items-center",
                                        attrs: { for: model.key }
                                      },
                                      [
                                        _c("input", {
                                          attrs: {
                                            type: "checkbox",
                                            id: model.key
                                          },
                                          domProps: { checked: model.enabled },
                                          on: {
                                            change: function($event) {
                                              return _vm.toggleTimeFrameFilter(
                                                model,
                                                $event.target.checked
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        model.color
                                          ? _c("div", {
                                              staticClass:
                                                "h-4 w-4 px-2 mx-2 border border-gray-900",
                                              style: {
                                                "background-color": model.color.formatRgb()
                                              }
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(model.label))
                                        ])
                                      ]
                                    )
                                  ])
                                }),
                                _vm._v(" "),
                                _c(
                                  "h3",
                                  { staticClass: "my-2 text-gray-800 text-lg" },
                                  [_vm._v("Map extent settings")]
                                ),
                                _vm._v(" "),
                                _c("div", [
                                  _c(
                                    "label",
                                    { staticClass: "flex items-center" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.filterExtent,
                                            expression: "filterExtent"
                                          }
                                        ],
                                        attrs: {
                                          type: "checkbox",
                                          id: "filterExtent"
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.filterExtent
                                          )
                                            ? _vm._i(_vm.filterExtent, null) >
                                              -1
                                            : _vm.filterExtent
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a = _vm.filterExtent,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.filterExtent = $$a.concat(
                                                    [$$v]
                                                  ))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.filterExtent = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.filterExtent = $$c
                                            }
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "px-2" }, [
                                        _vm._v("Filter projects by map extent")
                                      ])
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "my-2" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "px-2 py-1 border border-blue-900 rounded-md bg-blue-500 text-blue-100 flex items-center",
                                      on: { click: _vm.resetExtent }
                                    },
                                    [
                                      _c("i", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.feather.icons[
                                              "maximize-2"
                                            ].toSvg({ class: "w-5 h-5" })
                                          )
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "pl-2" }, [
                                        _vm._v("Reset map extent")
                                      ])
                                    ]
                                  )
                                ])
                              ],
                              2
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("transition", { attrs: { name: "fade" } }, [
                      _c(
                        "ul",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showProjects,
                              expression: "showProjects"
                            }
                          ],
                          staticClass: "list-none"
                        },
                        _vm._l(_vm.filteredProjects, function(project) {
                          return _c(
                            "li",
                            { key: project.id + "-" + project.number },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "flex flex-col my-2 px-2 py-3 shadow border rounded bg-white hover:bg-blue-100 focus:bg-blue-100",
                                  attrs: { to: project.id, append: "" },
                                  nativeOn: {
                                    mouseover: function($event) {
                                      return _vm.highlightProject({
                                        project: project
                                      })
                                    },
                                    focus: function($event) {
                                      return _vm.highlightProject({
                                        project: project
                                      })
                                    },
                                    click: function($event) {
                                      return _vm.selectProject(project)
                                    }
                                  }
                                },
                                [
                                  _c("h3", [_vm._v(_vm._s(project.name))]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "my-2 text-sm" }, [
                                    _vm._v(_vm._s(project.description))
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex flex-row flex-wrap -mx-2 text-xs text-gray-600"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "mx-2 flex flex-row items-center"
                                        },
                                        [
                                          _c("i", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.feather.icons[
                                                  "dollar-sign"
                                                ].toSvg({
                                                  class: "w-3 h-3 mr-1"
                                                })
                                              )
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                project.estimatedCost.toLocaleString()
                                              )
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "mx-2 flex flex-row items-center"
                                        },
                                        [
                                          _c("i", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.feather.icons.calendar.toSvg(
                                                  {
                                                    class: "w-3 h-3 mr-1"
                                                  }
                                                )
                                              )
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(project.estimatedTimeframe)
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ])
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "border-current border-b-2 transition ease-in-out duration-150 hover:text-blue-600 focus:text-blue-600",
                            attrs: { to: "/projects" }
                          },
                          [_vm._v("Back to results")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.projectList && _vm.projectList.length > 1
                      ? _c("pager", {
                          staticClass: "my-3",
                          attrs: { list: _vm.projectList },
                          on: {
                            next: function($event) {
                              return _vm.handleProjectChange(
                                _vm.projectIndex + 1
                              )
                            },
                            prev: function($event) {
                              return _vm.handleProjectChange(
                                _vm.projectIndex - 1
                              )
                            }
                          },
                          model: {
                            value: _vm.projectIndex,
                            callback: function($$v) {
                              _vm.projectIndex = $$v
                            },
                            expression: "projectIndex"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedProjects && _vm.selectedProjects.length > 1
                      ? _c("pager", {
                          staticClass: "my-3",
                          attrs: {
                            list: _vm.selectedProjects.reduce(function(
                              prev,
                              curr
                            ) {
                              prev.push(curr.id)
                              return prev
                            },
                            [])
                          },
                          on: {
                            next: function($event) {
                              return _vm.handleSelectionChange(
                                _vm.selectionIndex + 1
                              )
                            },
                            prev: function($event) {
                              return _vm.handleSelectionChange(
                                _vm.selectionIndex - 1
                              )
                            }
                          },
                          model: {
                            value: _vm.selectionIndex,
                            callback: function($$v) {
                              _vm.selectionIndex = $$v
                            },
                            expression: "selectionIndex"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedProjects &&
                    _vm.selectedProjects[_vm.selectionIndex]
                      ? _c("project-component", {
                          staticClass: "my-3",
                          attrs: {
                            project: _vm.selectedProjects[_vm.selectionIndex]
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "section",
        {
          staticClass:
            "w-full sm:w-2/3 md:w-full lg:w-2/3 xl:w-3/4 h-screen-50 sm:h-screen md:h-screen-50 lg:h-(screen-16) overflow-y-auto"
        },
        [
          _c("app-map", {
            attrs: { layers: _vm.mapLayers },
            on: { click: _vm.handleClick }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }