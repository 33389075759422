

















































import Vue from 'vue';
export default Vue.extend({
  name: 'Project',
  props: {
    project: {
      type: Object,
      required: true
    }
  }
});
