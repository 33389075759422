





































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { RawLocation, Route } from 'vue-router';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import intersects from '@turf/boolean-intersects';
import { BBox, feature } from '@turf/helpers';
import feather from 'feather-icons';

import { Extent } from 'esri/geometry';
import MapView from 'esri/views/MapView';

import AppMap from '@/components/Map.vue';
import Messages from '@/components/message/List.vue';
import ProjectComponent from '@/components/Project.vue';
import Pager from '@/components/Pager.vue';
import Search from '@/components/icons/Search.vue';

import { ProjectState, Project, ViewModel } from '../store/projects/types';
import bboxPolygon from '@turf/bbox-polygon';
import proj4 from 'proj4';

@Component({
  name: 'Projects',
  components: {
    AppMap,
    Messages,
    ProjectComponent,
    Pager,
    Search
  },
  data() {
    return { feather };
  },
  computed: {
    ...mapState(['message']),
    ...mapState('map', ['view', 'extent']),
    ...mapState('projects', {
      models: (state: ProjectState) => state.models,
      projects: (state: ProjectState) => state.list,
      index: (state: ProjectState) => state.index
    }),
    ...mapGetters('projects', ['mapLayers'])
  },
  methods: {
    ...mapActions('map', ['setLayerVisibility', 'resetExtent']),
    ...mapMutations('projects', ['setModels']),
    ...mapActions('projects', ['findProjects', 'highlightProject'])
  },
  beforeRouteEnter(to: Route, from: Route, next: (to?: RawLocation | false | ((vm: Vue) => void)) => void) {
    next(vm => {
      vm.$store.dispatch('clearMessages');

      // select the projects layer
      vm.$store.dispatch('map/setLayerVisibility', {
        layerId: 'projects-10',
        visible: true
      });
      vm.$store.dispatch('map/setLayerVisibility', {
        layerId: 'projects-20',
        visible: true
      });
      vm.$store.dispatch('map/setLayerVisibility', {
        layerId: 'projects-NA',
        visible: true
      });

      if (to.params.id) {
        vm.$store.dispatch('projects/highlightProject', {
          project: { id: to.params.id },
          move: true
        });
      } else {
        vm.$store.dispatch('projects/findProjects');
      }
    });
  },
  beforeRouteUpdate(to: Route, from: Route, next: (to?: RawLocation | false | ((vm: Vue) => void)) => void) {
    next();
  }
})
export default class Projects extends Vue {
  view!: MapView;
  extent!: Extent;
  models!: Array<ViewModel>;
  projects!: Array<Project>;
  index!: lunr.Index;

  setLayerVisibility!: (payload: { layerId: string; visible: boolean }) => void;
  setModels!: (models: Array<ViewModel>) => void;
  highlightProject!: (payload: { project: Project; move?: boolean }) => void;

  searchText = '';
  projectIndex = 0;
  selectionIndex = 0;
  show = {};
  showProjects = true;
  showFilters = true;
  filterExtent = false;
  // this represents a list of projects that someone got to through clicking on the map
  //    it functions as a list of routes to traverse
  projectList = new Array<string>();

  get total() {
    return this.models.reduce((prev: number, curr: ViewModel) => {
      return prev + (curr.enabled ? curr.count : 0);
    }, 0);
  }

  get filteredProjects() {
    const enabledTimeframes = this.models.reduce((prev: Map<string, boolean>, curr: ViewModel) => {
      if (curr.enabled) {
        prev.set(curr.value, curr.enabled);
      }
      return prev;
    }, new Map<string, boolean>());

    let projects = this.projects;

    if (this.searchText && this.searchText.length > 0) {
      projects = this.index.search(this.searchText).map((val: lunr.Index.Result) => {
        return (
          this.projects.find((proj: Project) => {
            return proj.id == val.ref;
          }) || { id: val.ref }
        );
      });
    }

    if (this.filterExtent) {
      let { xmin, ymin, xmax, ymax } = this.extent;
      [xmin, ymin] = proj4(this.extent.spatialReference.wkid.toString(), 'EPSG:4326', [xmin, ymin]);
      [xmax, ymax] = proj4(this.extent.spatialReference.wkid.toString(), 'EPSG:4326', [xmax, ymax]);
      const bbox: BBox = [xmin, ymin, xmax, ymax];
      const polygon = bboxPolygon(bbox);
      projects = projects.reduce((prev: Array<Project>, curr: Project) => {
        if (intersects(polygon, feature(curr.geometry))) {
          prev.push(curr);
        }
        return prev;
      }, new Array<Project>());
    }

    return projects.reduce((prev: Array<Project>, curr: Project) => {
      if (curr.estimatedTimeframe && enabledTimeframes.has(curr.estimatedTimeframe)) {
        prev.push(curr);
      }
      return prev;
    }, new Array<Project>());
  }

  get selectedProjects() {
    return this.projects.reduce((prev: Array<Project>, curr: Project) => {
      if (curr.id == this.$route.params.id) {
        prev.push(curr);
      }
      return prev;
    }, new Array<Project>());
  }

  get dataset() {
    return this.models.map((model: ViewModel) => {
      model.count = this.filteredProjects.reduce((prev: number, curr: Project) => {
        if (curr.estimatedTimeframe == model.value) {
          prev = prev + 1;
        }
        return prev;
      }, 0);
      return model;
    });
  }

  toggleTimeFrameFilter(model: ViewModel, value: boolean) {
    this.setModels(
      this.models.reduce((prev: Array<ViewModel>, curr: ViewModel) => {
        if (curr.key === model.key) {
          curr.enabled = value;
          curr.mapLayer.visible = value;
          this.setLayerVisibility({ layerId: curr.key, visible: value });
        }
        return prev;
      }, this.models)
    );
  }

  selectProject(project: Project) {
    this.projectList = new Array<string>();
    this.selectionIndex = 0;
    this.highlightProject({ project, move: true });
  }

  handleSearchChange(value: string) {
    this.searchText = value;
  }

  handleProjectChange(index: number) {
    this.projectIndex = index;
    this.selectionIndex = 0;

    const id = this.projectList[this.projectIndex];
    if (id != this.$router.currentRoute.params.id) {
      this.$router.push({ name: 'projects', params: { id } });
    }

    this.handleSelectionChange(this.selectionIndex);
  }

  handleSelectionChange(index: number) {
    this.selectionIndex = index;

    const project = this.selectedProjects[this.selectionIndex];
    this.highlightProject({ project, move: true });
  }

  handleClick(event: __esri.MapViewClickEvent) {
    this.view.hitTest(event).then((response: __esri.HitTestResult) => {
      if (response.results.length) {
        this.selectionIndex = this.projectIndex = 0;
        const projects = response.results.reduce((prev, curr) => {
          // push each id to the pagination component
          const graphic = curr.graphic;

          if (!graphic.attributes) return prev;

          if (Object.keys(graphic.attributes).find(key => key === 'TranPlanID')) {
            prev.add(curr.graphic.attributes.TranPlanID);
          }

          return prev;
        }, new Set<string>());
        this.projectList = Array.from(projects);
        this.$router.push({
          name: 'projects',
          params: { id: this.projectList[0] }
        });
        this.handleSelectionChange(this.selectionIndex);
      }
    });
  }
}
