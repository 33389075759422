var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("article", [
    _c("h1", { staticClass: "mb-3 text-3xl lg:text-4xl" }, [
      _vm._v(_vm._s(_vm.project.name))
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "my-3 text-xl text-gray-700" }, [
      _vm._v(_vm._s(_vm.project.description))
    ]),
    _vm._v(" "),
    _c("dl", [
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 my-3" },
        [
          _c("dt", { staticClass: "text-gray-700" }, [
            _vm._v("Project number")
          ]),
          _vm._v(" "),
          _c("dd", [_vm._v(_vm._s(_vm.project.number))])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 my-3" },
        [
          _c("dt", { staticClass: "text-gray-700" }, [
            _vm._v("Transportation plan ID")
          ]),
          _vm._v(" "),
          _c("dd", [_vm._v(_vm._s(_vm.project.id))])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 my-3" },
        [
          _c("dt", { staticClass: "text-gray-700" }, [_vm._v("Location")]),
          _vm._v(" "),
          _c("dd", [_vm._v(_vm._s(_vm.project.location))])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 my-3" },
        [
          _c("dt", { staticClass: "text-gray-700" }, [_vm._v("Lead agency")]),
          _vm._v(" "),
          _c("dd", [_vm._v(_vm._s(_vm.project.agency))])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 my-3" },
        [
          _c("dt", { staticClass: "text-gray-700" }, [
            _vm._v("Estimated cost")
          ]),
          _vm._v(" "),
          _c("dd", [
            _vm._v(
              _vm._s(
                _vm.project.estimatedCost
                  ? _vm.project.estimatedCost.toLocaleString()
                  : "N/A"
              )
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 my-3" },
        [
          _c("dt", { staticClass: "text-gray-700" }, [
            _vm._v("Estimated time frame")
          ]),
          _vm._v(" "),
          _c("dd", [_vm._v(_vm._s(_vm.project.estimatedTimeframe))])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 my-3" },
        [
          _c("dt", { staticClass: "text-gray-700" }, [
            _vm._v("Project district")
          ]),
          _vm._v(" "),
          _c("dd", [_vm._v(_vm._s(_vm.project.district))])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 my-3" },
        [
          _c("dt", { staticClass: "text-gray-700" }, [
            _vm._v("Facility owner")
          ]),
          _vm._v(" "),
          _c("dd", [_vm._v(_vm._s(_vm.project.facilityOwner))])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 my-3" },
        [
          _c("dt", { staticClass: "text-gray-700" }, [_vm._v("Pattern area")]),
          _vm._v(" "),
          _c("dd", [_vm._v(_vm._s(_vm.project.patternArea))])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 my-3" },
        [
          _c("dt", { staticClass: "text-gray-700" }, [
            _vm._v("Funding category")
          ]),
          _vm._v(" "),
          _c("dd", [_vm._v(_vm._s(_vm.project.fundingCategory))])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }